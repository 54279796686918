// App.js
import React from 'react';
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMobile, faGears, faGlobe, faCartShopping, faNewspaper, faUsers, faAddressBook, faTools, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import headerImage from './LOGOMED.png';
import Image1 from './1.png';
import Image2 from './2.png';
import Image3 from './3.png';
import Image4 from './4.png';
import Image5 from './5.png';
import Image6 from './6.png';
import services_tele from './services_tele.webp';
import services_medhelp from './services_medhelp_.webp';
import services_emagrecimento from './services_emagrecimento_.webp';
import services_hormone from './services_hormone_.webp';

library.add(faMobile, faGears, faGlobe, faCartShopping, faNewspaper, faUsers, faAddressBook, faTools, faUser );

const ScrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

const PageConsultasEmTelemedicina = () => (
  <div style={{ padding: '0px 20px 20px 20px' }}>
    <h2 style={{ textAlign: 'center' }}>Consultas em Telemedicina</h2>
    <p>Atendimento médico de qualidade, onde e quando você precisa, com nossa telemedicina.</p>
    <div style={{ textAlign: 'center' }}><img src={services_tele} style={{ width: '200px'}} /></div>
    <hr/>
    <h4>Atendimento Telemedicina</h4>
    <p>Nossos clientes tem acesso imediato a consultas em telemedicina pela nossa plataforma online. Se ainda não é cliente entre em contato conosco.</p>
    <div style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }}><Link to="https://medstationbr.rapidoc.tech/login" className="red-link" target="_blank" onClick={ScrollToTop}>CLIQUE AQUI E ACESSE AGORA</Link></div>
    <div style={{ textAlign: 'center', margin: '130px 0px 0px 0px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>

  </div>
);

const PageMedHelp = () => (
  <div style={{ padding: '0px 20px 20px 20px' }}>
    <h2 style={{ textAlign: 'center' }}>Medhelp</h2>
    <p>Priorize sua saúde e economize com o Cartão Medhelp: consultas ilimitadas e descontos exclusivos.</p>
    <div style={{ textAlign: 'center' }}><img src={services_medhelp} style={{ width: '200px'}} /></div>
    <div style={{ textAlign: 'center', margin: '130px 0px 0px 0px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);

const PageWeightLoss = () => (
  <div style={{ padding: '0px 20px 20px 20px' }}>
    <h2 style={{ textAlign: 'center' }}>Weight Loss</h2>    
    <p>Transformação pessoal com nosso programa de emagrecimento personalizado e duradouro.</p>
    <div style={{ textAlign: 'center' }}><img src={services_emagrecimento} style={{ width: '200px'}} /></div>
    <div style={{ textAlign: 'center', margin: '130px 0px 0px 0px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);

const PageReposicaoHormonal = () => (
  <div style={{ padding: '0px 20px 20px 20px' }}>
    <h2 style={{ textAlign: 'center' }}>Reposição Hormonal</h2>
    <p>Restaure vitalidade equilibrando hormônios com nossa terapia especializada.</p>
    <div style={{ textAlign: 'center' }}><img src={services_hormone} style={{ width: '200px'}} /></div>
    <div style={{ textAlign: 'center', margin: '130px 0px 0px 0px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);

const PageAgendamento = () => (
  <div style={{ padding: '0px 20px 20px 20px' }}>
    <h2 style={{ textAlign: 'center' }}>Agendamento</h2>
    <p>Nossos consultores altamente capacitados estão prontos para oferecer um atendimento personalizado e solucionar todas as suas necessidades.</p>
    <div style={{ textAlign: 'center', margin: '10px 0px 0px 0px' }}><Link to="https://api.whatsapp.com/send?phone=5511950473796" target="_blank" className="red-link">Clique aqui e fale conosco.</Link></div>
    <div style={{ textAlign: 'center', margin: '130px 0px 0px 0px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);

const PageContato = () => (
  <div style={{ padding: '0px 20px 20px 20px' }}>
    <h2 style={{ textAlign: 'center' }}>Contato</h2>
    <p>Telefone: (XX) XXXX-XXXX</p>
    <p>e-Mail: (XX) XXXX-XXXX</p>
    <p>WhatsApp: <Link to="https://api.whatsapp.com/send?phone=5511950473796" target="_blank" className="red-link">Clique aqui</Link></p>
    <p>Endereço: São Paulo - SP</p>
    <div style={{ textAlign: 'center', margin: '130px 0px 0px 0px' }}><Link to="/" className="red-link" onClick={ScrollToTop}>VOLTAR AO MENU</Link></div>
  </div>
);

const App = () => (
  <Router>
    <div>
      <header style={{ textAlign: 'center' }}>
      <Link to="/"><img src={headerImage} style={{ width: '150px', marginTop: '20px' }} /></Link>
      </header>
      
      
      
      <Routes>
      
          <Route path="/" element={

<div>
  
  <h4 style={{ textAlign: 'center', margin: '20px' }}>Na Medstation, valorizamos a integridade, a ética, o cuidado, o respeito e a compaixão.</h4><p style={{ textAlign: 'center', margin: '20px' }}>Nossa identidade está fundamentada totalmente centrada no paciente, alvo de nossa proatividade, empatia e competência. Acima de tudo, buscamos resolver os problemas de saúde de nossos clientes.</p>
          
          <div className="container">
        
           
              <div className="item">
                <Link to="/consultasEmTelemedicina" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '0px' }}>
                  <img src={Image4} style={{ width: '40px'}} />
                  </div>
                  Consultas em Telemedicina
                </Link>
              </div>
              <div className="item">
                <Link to="/medHelp" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Image3} style={{ width: '40px'}} />
                  </div>
                  Medhelp
                </Link>
              </div>
              <div className="item">
                <Link to="/weightLoss" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Image2} style={{ width: '40px'}} />
                  </div>
                  Weight Loss
                </Link>
              </div>        
              <div className="item">
                <Link to="/reposicaoHormonal" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Image1} style={{ width: '40px'}} />
                  </div>
                  Reposição Hormonal
                </Link>
              </div>
              <div className="item">
                <Link to="/agendamento" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Image5} style={{ width: '40px'}} />
                  </div>
                  Agendamento
                </Link>
              </div>
              <div className="item">
                <Link to="/contato" className="black-link" onClick={ScrollToTop}>
                  <div style={{ marginBottom: '10px' }}>
                  <img src={Image6} style={{ width: '40px'}} />
                  </div>
                  Contato
                </Link>
              </div>
              {/* Add more links for other icons as needed */}
            
          
          </div></div>
          } />
          <Route path="/consultasEmTelemedicina" element={<PageConsultasEmTelemedicina />} />
          <Route path="/medHelp" element={<PageMedHelp />} />
          <Route path="/weightLoss" element={<PageWeightLoss />} />
          <Route path="/reposicaoHormonal" element={<PageReposicaoHormonal />} />
          <Route path="/agendamento" element={<PageAgendamento />} />
          <Route path="/contato" element={<PageContato />} />
          {/* Add routes for other items as needed */}
      </Routes>
      <footer style={{ textAlign: 'center', padding: '20px', background: '#f0f0f0' }}>
        Copyright 2024
      </footer>
    </div>
  </Router>
);

export default App;
